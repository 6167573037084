@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

/*********************************************/
@-webkit-keyframes revolve-in-forward {
  0% {
    opacity: 0;
    -webkit-transform: scale(0, 0) translateX(0px);
            transform: scale(0, 0) translateX(0px); }
  50% {
    opacity: 0.5;
    -webkit-transform: scale(0.5, 0.5) translateX(100%);
            transform: scale(0.5, 0.5) translateX(100%); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1) translateX(0px);
            transform: scale(1, 1) translateX(0px); } }
@keyframes revolve-in-forward {
  0% {
    opacity: 0;
    -webkit-transform: scale(0, 0) translateX(0px);
            transform: scale(0, 0) translateX(0px); }
  50% {
    opacity: 0.5;
    -webkit-transform: scale(0.5, 0.5) translateX(100%);
            transform: scale(0.5, 0.5) translateX(100%); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1) translateX(0px);
            transform: scale(1, 1) translateX(0px); } }

@-webkit-keyframes revolve-out-forward {
  50% {
    opacity: 0.5;
    -webkit-transform: scale(0.5, 0.5) translateX(-100%);
            transform: scale(0.5, 0.5) translateX(-100%); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0, 0) translateX(0px);
            transform: scale(0, 0) translateX(0px); } }

@keyframes revolve-out-forward {
  50% {
    opacity: 0.5;
    -webkit-transform: scale(0.5, 0.5) translateX(-100%);
            transform: scale(0.5, 0.5) translateX(-100%); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0, 0) translateX(0px);
            transform: scale(0, 0) translateX(0px); } }

@-webkit-keyframes revolve-in-backward {
  0% {
    opacity: 0;
    -webkit-transform: scale(0, 0) translateX(0px);
            transform: scale(0, 0) translateX(0px); }
  50% {
    opacity: 0.5;
    -webkit-transform: scale(0.5, 0.5) translateX(-100%);
            transform: scale(0.5, 0.5) translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1) translateX(0px);
            transform: scale(1, 1) translateX(0px); } }

@keyframes revolve-in-backward {
  0% {
    opacity: 0;
    -webkit-transform: scale(0, 0) translateX(0px);
            transform: scale(0, 0) translateX(0px); }
  50% {
    opacity: 0.5;
    -webkit-transform: scale(0.5, 0.5) translateX(-100%);
            transform: scale(0.5, 0.5) translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1, 1) translateX(0px);
            transform: scale(1, 1) translateX(0px); } }

@-webkit-keyframes revolve-out-backward {
  50% {
    opacity: 0.5;
    -webkit-transform: scale(0.5, 0.5) translateX(100%);
            transform: scale(0.5, 0.5) translateX(100%); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0, 0) translateX(0px);
            transform: scale(0, 0) translateX(0px); } }

@keyframes revolve-out-backward {
  50% {
    opacity: 0.5;
    -webkit-transform: scale(0.5, 0.5) translateX(100%);
            transform: scale(0.5, 0.5) translateX(100%); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0, 0) translateX(0px);
            transform: scale(0, 0) translateX(0px); } }

.body-overflow-hidden {
  overflow: hidden !important; }

.ng-image-gallery {
  outline: none;
  /*****************************************************************
								INLINE GALLERY
	/*****************************************************************/ }
  .ng-image-gallery * {
    outline: none; }
  .ng-image-gallery .ng-image-gallery-thumbnails:after {
    content: "";
    width: 0;
    height: 0;
    display: block;
    visibility: hidden;
    clear: both; }
  .ng-image-gallery .ng-image-gallery-thumbnails .thumb {
    float: left;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 3px;
    background-size: cover;
    background-position: center center;
    cursor: pointer; }
    .ng-image-gallery .ng-image-gallery-thumbnails .thumb:not(:last-child) {
      margin-right: 5px;
      margin-bottom: 5px; }
  .ng-image-gallery .ng-image-gallery-modal {
    position: fixed;
    z-index: 5000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; }
    .ng-image-gallery .ng-image-gallery-modal.ng-hide-add {
      opacity: 1; }
    .ng-image-gallery .ng-image-gallery-modal.ng-hide-add-active {
      opacity: 0; }
    .ng-image-gallery .ng-image-gallery-modal.ng-hide-remove {
      opacity: 0; }
    .ng-image-gallery .ng-image-gallery-modal.ng-hide-remove-active {
      opacity: 1; }
    .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-backdrop {
      background-color: rgba(0, 0, 0, 0.85);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1; }
    .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2; }
      .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .destroy-icons-container {
        position: absolute;
        right: 10px;
        top: 20px; }
        .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .destroy-icons-container .ext-url,
        .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .destroy-icons-container .close {
          position: relative;
          display: inline-block;
          width: 30px;
          height: 30px;
          overflow: hidden;
          cursor: pointer;
          text-decoration: none;
          color: #fff;
          vertical-align: bottom; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .destroy-icons-container .ext-url:hover,
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .destroy-icons-container .close:hover {
            -webkit-transition: background-color 0.3s ease-in-out;
            transition: background-color 0.3s ease-in-out;
            background-color: rgba(255, 255, 255, 0.1); }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .destroy-icons-container .ext-url:before,
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .destroy-icons-container .close:before {
            content: "";
            display: block;
            position: absolute;
            top: 5px;
            right: 5px;
            bottom: 5px;
            left: 5px;
            background-size: 100% 100%;
            background-repeat: no-repeat; }
        .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .destroy-icons-container .ext-url:before {
          background-image: url(../res/icons/external-link.svg); }
        .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .destroy-icons-container .close:before {
          background-image: url(../res/icons/close.svg); }
      .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .prev,
      .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .next {
        position: absolute;
        top: 50%;
        margin-top: -25px;
        width: 50px;
        height: 50px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        overflow: hidden;
        cursor: pointer; }
        .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .prev.bubbles-on,
        .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .next.bubbles-on {
          top: calc((100% - 50px) / 2); }
        @media screen and (max-width: 600px) {
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .prev,
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .next {
            margin-top: -15px;
            width: 30px;
            height: 30px; } }
      .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .prev {
        left: 0px;
        background-image: url(../res/icons/prev.svg); }
      .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .next {
        right: 0px;
        background-image: url(../res/icons/next.svg); }
      .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 80px;
        right: 80px;
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images {
          position: relative;
          -webkit-box-flex: 1;
              -ms-flex: 1;
                  flex: 1;
          width: 100%;
          /*****************************************************/
          /*****************************************************/ }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images .galleria-image {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            max-width: 100%;
            max-height: 100%;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-fade .galleria-image.ng-enter {
            -webkit-transition: opacity 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out;
            opacity: 0; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-fade .galleria-image.ng-enter-active {
            opacity: 1; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-fade .galleria-image.ng-leave-active {
            opacity: 0; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-fadeup .galleria-image.ng-enter {
            -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            opacity: 0;
            -webkit-transform: scale(0.98);
                    transform: scale(0.98); }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-fadeup .galleria-image.ng-enter-active {
            opacity: 1;
            -webkit-transform: scale(1);
                    transform: scale(1); }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-fadeup .galleria-image.ng-leave-active {
            opacity: 0; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-zoom .galleria-image.ng-enter {
            -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            opacity: 0;
            -webkit-transform: scale(0);
                    transform: scale(0); }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-zoom .galleria-image.ng-enter-active {
            opacity: 1;
            -webkit-transform: scale(1);
                    transform: scale(1); }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-zoom .galleria-image.ng-leave {
            -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-zoom .galleria-image.ng-leave-active {
            opacity: 0;
            -webkit-transform: scale(0);
                    transform: scale(0); }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-slide.img-move-dir-backward .galleria-image.ng-enter {
            -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            -webkit-transform: translateX(-100%);
                    transform: translateX(-100%);
            opacity: 0; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-slide.img-move-dir-backward .galleria-image.ng-enter-active {
            -webkit-transform: translateX(0px);
                    transform: translateX(0px);
            opacity: 1; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-slide.img-move-dir-backward .galleria-image.ng-leave {
            -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-slide.img-move-dir-backward .galleria-image.ng-leave-active {
            opacity: 0;
            -webkit-transform: translateX(100%);
                    transform: translateX(100%); }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-slide.img-move-dir-forward .galleria-image.ng-enter {
            -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            -webkit-transform: translateX(100%);
                    transform: translateX(100%);
            opacity: 0; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-slide.img-move-dir-forward .galleria-image.ng-enter-active {
            -webkit-transform: translateX(0px);
                    transform: translateX(0px);
            opacity: 1; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-slide.img-move-dir-forward .galleria-image.ng-leave {
            -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-slide.img-move-dir-forward .galleria-image.ng-leave-active {
            opacity: 0;
            -webkit-transform: translateX(-100%);
                    transform: translateX(-100%); }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-pop.img-move-dir-backward .galleria-image.ng-enter {
            -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            -webkit-transform: translateX(-100%) scale(0, 0);
                    transform: translateX(-100%) scale(0, 0);
            opacity: 0; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-pop.img-move-dir-backward .galleria-image.ng-enter-active {
            -webkit-transform: translateX(0px) scale(1, 1);
                    transform: translateX(0px) scale(1, 1);
            opacity: 1; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-pop.img-move-dir-backward .galleria-image.ng-leave {
            -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-pop.img-move-dir-backward .galleria-image.ng-leave-active {
            opacity: 0;
            -webkit-transform: translateX(100%) scale(0, 0);
                    transform: translateX(100%) scale(0, 0); }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-pop.img-move-dir-forward .galleria-image.ng-enter {
            -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            -webkit-transform: translateX(100%) scale(0, 0);
                    transform: translateX(100%) scale(0, 0);
            opacity: 0; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-pop.img-move-dir-forward .galleria-image.ng-enter-active {
            -webkit-transform: translateX(0px) scale(1, 1);
                    transform: translateX(0px) scale(1, 1);
            opacity: 1; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-pop.img-move-dir-forward .galleria-image.ng-leave {
            -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-pop.img-move-dir-forward .galleria-image.ng-leave-active {
            opacity: 0;
            -webkit-transform: translateX(-100%) scale(0, 0);
                    transform: translateX(-100%) scale(0, 0); }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-revolve.img-move-dir-forward .galleria-image.ng-enter {
            -webkit-animation: revolve-in-forward;
                    animation: revolve-in-forward;
            -webkit-animation-duration: 0.3s;
                    animation-duration: 0.3s; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-revolve.img-move-dir-forward .galleria-image.ng-leave {
            -webkit-animation: revolve-out-forward;
                    animation: revolve-out-forward;
            -webkit-animation-duration: 0.3s;
                    animation-duration: 0.3s; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-revolve.img-move-dir-backward .galleria-image.ng-enter {
            -webkit-animation: revolve-in-backward;
                    animation: revolve-in-backward;
            -webkit-animation-duration: 0.3s;
                    animation-duration: 0.3s; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-images.img-anim-revolve.img-move-dir-backward .galleria-image.ng-leave {
            -webkit-animation: revolve-out-backward;
                    animation: revolve-out-backward;
            -webkit-animation-duration: 0.3s;
                    animation-duration: 0.3s; }
        .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-bubbles {
          -webkit-box-flex: 0;
              -ms-flex: 0 0 50px;
                  flex: 0 0 50px;
          text-align: center;
          overflow: hidden;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -ms-flex-line-pack: center;
              align-content: center;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
          .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-bubbles span {
            -webkit-box-flex: 0;
                -ms-flex: none;
                    flex: none;
            position: relative;
            display: inline-block;
            margin: 2px 4px;
            cursor: pointer;
            overflow: hidden;
            border-radius: 100%;
            background-size: cover;
            background-position: center center;
            vertical-align: middle; }
            .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-bubbles span:not(.img-bubble) {
              width: 12px;
              height: 12px;
              background-color: rgba(255, 255, 255, 0.5);
              border-radius: 100%;
              overflow: hidden;
              -webkit-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out; }
              .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-bubbles span:not(.img-bubble).active {
                background-color: #fff; }
            .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-bubbles span.img-bubble {
              box-sizing: border-box;
              width: 12px;
              height: 12px;
              margin: 2px 4px;
              border: 1px solid transparent;
              -webkit-transition: border 0.3s ease-in-out;
              transition: border 0.3s ease-in-out; }
              .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-bubbles span.img-bubble.active {
                border: 1px solid #fff; }
    .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-loader {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-loader .spinner {
        -webkit-box-flex: 0;
            -ms-flex: none;
                flex: none;
        width: 50px;
        height: 50px;
        text-align: center;
        font-size: 10px; }
        .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-loader .spinner > div {
          background-color: #fff;
          height: 100%;
          width: 7px;
          margin: 0 3px 0 0;
          display: inline-block;
          -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
          animation: sk-stretchdelay 1.2s infinite ease-in-out; }
        .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-loader .spinner .rect2 {
          -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
        .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-loader .spinner .rect3 {
          -webkit-animation-delay: -1.0s;
          animation-delay: -1.0s; }
        .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-loader .spinner .rect4 {
          -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
        .ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-loader .spinner .rect5 {
          -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
  .ng-image-gallery.inline .ng-image-gallery-modal {
    position: relative;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    height: 300px; }
    .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .destroy-icons-container {
      top: 0; }
      .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .destroy-icons-container .ext-url {
        margin: 0; }
        .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .destroy-icons-container .ext-url:before {
          background-image: url(../res/icons/external-link-inline.svg); }
    .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .prev {
      background-image: url(../res/icons/prev-inline.svg); }
    .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .next {
      background-image: url(../res/icons/next-inline.svg); }
    .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .prev, .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .next {
      margin-top: -15px;
      width: 30px;
      height: 30px; }
    .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-bubbles span:not(.img-bubble) {
      background-color: rgba(0, 0, 0, 0.5); }
      .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-bubbles span:not(.img-bubble).active {
        background-color: #333; }
    .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-bubbles span.img-bubble.active {
      border: 1px solid #000; }
    .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-loader .spinner > div {
      background-color: #999; }
